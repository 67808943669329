import React from 'react';
import './About.css'; 
import Header from './Header';
import logoSmall from '../assets/about.png'

const About = () => {
  return (
    <>
      <Header />
      <div className="about-container">
      <div className="about-content">
        <div className="about-text">
          <h1>About Us</h1>
          <p>
          This project was created by Jacob and Ani, two adventurous friends who embarked on a spontaneous journey at the airport. Standing at the terminal, they decided to book their flights on the day of departure, filtered their options by budget, and then used a website they created to spin a virtual wheel of destinations.
          </p>
          <p>
          When the wheel landed on London, their thrilling adventure began. This experience inspired Flight Roulette, a platform that allows you to enjoy the excitement of spontaneous travel, just like Jacob and Ani did on that memorable day.
          </p>
        </div>
        <div className="about-image">
          <img
            src={logoSmall} // Replace with your image URL
            alt="Team"
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
