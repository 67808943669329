import React, { useState } from 'react';
import './App.css'; // Make sure to create this CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import heroImage from './assets/hero-2.png'; // Adjust the path if necessary
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Results from './components/Results';
import Search from './components/Search';
import SpinningWheel from './components/SpinningWheel';
import About from './components/About';

function App() {
  const [flightsData, setFlightsData] = useState(null);
  const [addedFlights, setAddedFlights] = useState([]);
  const [numAddedFlights, setNumAddedFlights] = useState(0);

  return (
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<Search setFlightsData={setFlightsData} />} />
            <Route path='/results' element={<Results addedFlights={addedFlights} setAddedFlights={setAddedFlights} numAddedFlights={numAddedFlights} setNumAddedFlights={setNumAddedFlights}/>} />
            <Route path='/spin' element={<SpinningWheel />} />
            <Route path='/about' element={<About />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;

