import React, { useEffect, useState } from 'react';
import './Results.css';
import { useNavigate } from "react-router-dom";
import Header from './Header';

const Results = ({ addedFlights, setAddedFlights, numAddedFlights, setNumAddedFlights }) => {
  const [isPulsing, setIsPulsing] = useState(false);
  const [addedFlightsToAirportMap, setAddedFlightsToAirportMap] = useState(new Map());

  let navigate = useNavigate();

  let flightsData = JSON.parse(localStorage.getItem('flightsData'));

  useEffect(() => {
    if (!flightsData || !flightsData.results) {
      // flightsData isn't set, going to home page.
      navigate('/');
    }
  }, [flightsData, navigate]);

  useEffect(() => {
    if (numAddedFlights > 1) {
      setIsPulsing(true);
    } else {
      setIsPulsing(false);
    }
  }, [numAddedFlights]);

  const isFlightAdded = (result) => {
    return addedFlights.includes(result.destinationCity);
  }

  const handleAddFlight = (result) => {
    if (!isFlightAdded(result)) {
      setAddedFlights((prevAddedFlights) => [...prevAddedFlights, result.destinationCity]);

      // Create a new Map with the updated data
      const newMap = new Map(addedFlightsToAirportMap);
      newMap.set(result.destinationCity, result.airport); // Set the airport code as the value

      setAddedFlightsToAirportMap(newMap);
      setNumAddedFlights((prevNumAddedFlights) => prevNumAddedFlights + 1);
    }
  }

  const handleSpinClick = () => {
    if (numAddedFlights < 2) {
      return
    }

    localStorage.setItem('addedFlightsToAirportMap', JSON.stringify([...addedFlightsToAirportMap]));

    navigate('/spin');
  };

  const handleRemoveFlight = (flightToRemove) => {
    const updatedFlights = addedFlights.filter((flight) => flight !== flightToRemove);
    setAddedFlights(updatedFlights);

    // Create a new Map without the removed flight
    const newMap = new Map(addedFlightsToAirportMap);
    newMap.delete(flightToRemove);

    setAddedFlightsToAirportMap(newMap);
    setNumAddedFlights((prevNumAddedFlights) => prevNumAddedFlights - 1);
  };

  return (
    <>
      <Header />
      <div className="results-header">
          <h2>Your Search Results</h2>
        </div>
      <div className="results-container">
        

        {flightsData && flightsData.results? (
          <>
            {flightsData.results.map((result, index) => (
              <div key={index} className="flight-card">
                <div className="flight-details">
                  <span className="label">To:</span>
                  <span className={`value ${result.destinationCity.length > 10 ? 'truncate-text' : ''}`}>
                    {result.destinationCity}
                  </span>
                </div>
                <div className="flight-details">
                  <span className="label">From:</span>
                  <span className="value">{flightsData.origin}</span>
                </div>
                <div className="flight-details">
                  <span className="label">Price (est.):</span>
                  <span className="value">~ {result.price} ({flightsData.currency})</span>
                </div>

                <div className="book-button-container">
                  {isFlightAdded(result) ? (
                    <>
                      <button className="book-button added">Added</button>
                      <button className="remove-button" onClick={() => handleRemoveFlight(result.destinationCity)}>
                        X
                      </button>
                    </>
                  ) : (
                    <button
                      className={`book-button ${isPulsing ? 'pulse' : ''}`}
                      onClick={() => handleAddFlight(result)}
                    >
                      Add It
                    </button>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <p>Loading flight data...</p>
        )}
      </div>
      {/* <div className="added-flights-info">
          Flights added to the Wheel: <strong>{numAddedFlights}</strong>
        </div> */}
      <button
        className={`spin-wheel-button ${isPulsing ? "pulse" : ""} ${numAddedFlights < 2 ? "disabled" : ""}`}
        onClick={handleSpinClick}
      >
        Spin the Wheel
      </button>
    </>
  );
};

export default Results;
