import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import currenciesData from '../assets/currencies.json'
import logoSmall from '../assets/logo-small.png'

const Search = ({ setFlightsData }) => {
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    startingAirport: '',
    departureDate: '',
    returnDate: '',
    budget: '',
    currency: 'USD', // Set a default value
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currencies = currenciesData.currencies; // State to hold the list of currencies

  const routeChange = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true); // Start submitting

    // Extract form field values from formData
    const {
      startingAirport,
      departureDate,
      returnDate,
      budget,
      currency,
    } = formData;

    const fromYear = departureDate.split("-")[0];
    const fromMonth = departureDate.split("-")[1];
    const fromDay = departureDate.split("-")[2];
    const toYear = returnDate.split("-")[0];
    const toMonth = returnDate.split("-")[1];
    const toDay = returnDate.split("-")[2];
    const origin = startingAirport.replace(" ", "");

    const requestData = {
      fromYear,
      fromMonth,
      fromDay,
      toYear,
      toMonth,
      toDay,
      origin
    };

    try {

      localStorage.clear();

      const response = await fetch(`https://j6s4b9afck.execute-api.us-east-1.amazonaws.com/flights/getFlightInfo?budget=${budget}&currency=${currency}&fromDay=${fromDay}&fromMonth=${fromMonth}&fromYear=${fromYear}&origin=${origin}&toDay=${toDay}&toMonth=${toMonth}&toYear=${toYear}`)

      if (!response.ok) {
        setErrorMessage('Failed to fetch flight data. Please try again in a few minutes.');
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setFlightsData(data);
      localStorage.setItem('flightsData', JSON.stringify(data));

      // Store the input data in local storage
      localStorage.setItem('formData', JSON.stringify(formData));

      navigate('/results');
    } catch (error) {
      setErrorMessage("Error fetching flight data. Try adjusting your form criteria. Please ensure you gave a valid airport code (ex. JFK) and try again in a few minutes.")
      console.error('Error fetching flight data:', error);
    } finally {
      setIsSubmitting(false); // Stop submitting (whether success or error)
    }
  };

  // Create a function to handle form field changes and update formData
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const {
      startingAirport,
      departureDate,
      returnDate,
      budget,
    } = formData;
  
    // // Check if all required fields have values
    if (startingAirport === '' || departureDate === '' || returnDate === '' || budget === '') {
      setErrorMessage('Please fill out all required fields.');
      return false;
    }

    if (startingAirport.length < 3 || startingAirport.length > 4) {
      setErrorMessage('Starting airport must be a valid airport code (ex. JFK).');
      return false;
    }
  
    // Parse departureDate as a Date object
    const departureDateObj = new Date(departureDate);

    // Get the current date
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight for comparison

    // Check if departureDate is in the past or today
    var isOk = false
    if (departureDateObj < currentDate) {
      if (departureDateObj.getMonth === currentDate.getMonth 
        && departureDateObj.getDay === currentDate.getDay 
        && departureDateObj.getFullYear === currentDate.getFullYear) {
          isOk = true
        }
      if (!isOk) {
        setErrorMessage('Departure date cannot be in the past.');
        return false;
      }
    }
    const returnDateObj = new Date(returnDate);
  
    if (departureDateObj >= returnDateObj) {
      setErrorMessage('Departure date must be before the return date.');
      return false;
    }
  
    // If all checks pass, the form is valid
    setErrorMessage('');
    return true;
  };
  

  return (
    <>
      <Header />
      <img src={logoSmall} alt="Logo Image" width="200" height="200" className="centered-image" />
      <main>
        <div className="hero-container">
          <form className="search-form" onSubmit={routeChange}>
            <input
              type="text"
              placeholder="Starting Airport"
              name="startingAirport"
              value={formData.startingAirport.replace(" ","")}
              onChange={handleInputChange}
            />
            <input
              type="date"
              placeholder="Departure Date"
              name="departureDate"
              value={formData.departureDate}
              onChange={handleInputChange}
            />
            <input
              type="date"
              placeholder="Return Date"
              name="returnDate"
              value={formData.returnDate}
              onChange={handleInputChange}
            />
            <input
              type="number"
              placeholder="Budget"
              name="budget"
              value={formData.budget.replace(" ","")}
              onChange={handleInputChange}
            />
            <select
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
              >
              {currencies.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button type="submit" className={`go-button ${isSubmitting ? 'disabled' : ''}`}>
              {isSubmitting ? <div className="spinner" /> : 'Go'}
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default Search;
