import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "./SpinningWheel.css"; 
// import WheelComponent from "react-wheel-of-prizes";
import { Wheel } from 'react-custom-roulette'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket } from '@fortawesome/free-solid-svg-icons';

const SpinningWheel = ({ addedFlights }) => {
  const [winnerText, setWinnerText] = useState("");
  const [destinationText, setDestinationText] = useState("");
  const [ticketLink, setTicketLink] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isPulsing, setIsPulsing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * wheelData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  }

  const segColors = [
    "#EE4040",   // Red
    "#F0CF50",   // Yellow
    "#815CD1",   // Purple
    "#3DA5E0",   // Blue
    "#34A24F",   // Green
    "#FF8C42",   // Orange
    "#AC92EB",   // Lavender
    "#FFDB58",   // Gold
    "#42EADD",   // Cyan
    "#8B78E6",   // Indigo
    "#FF69B4",   // Pink
    "#FFB347",   // Peach
    "#7FFFD4",   // Aquamarine
    "#808080",   // Gray
    "#00FF00",   // Lime Green
    "#FF4500"    // Orange-Red
  ];
  
  var i = 0;
  var wheelData = []

  let navigate = useNavigate();
  let flightToAirportList = JSON.parse(localStorage.getItem('addedFlightsToAirportMap')) || [];

  useEffect(() => {
    if (!flightToAirportList.length) {
      // flightToAirportList isn't set, going to home page.
      navigate('/');
    }
  }, [flightToAirportList, navigate]);

  while (i < flightToAirportList.length) {
    wheelData.push({option: flightToAirportList[i][0], style: {backgroundColor: segColors[i]}});
    i++;
  }
  
  const onFinished = (winner) => {
    let flightToAirportMap = {};

    if (flightToAirportList) {
      flightToAirportList = flightToAirportList;
      flightToAirportList.forEach(([airportName, airportCode]) => {
        flightToAirportMap[airportName] = airportCode;
      });
    }
    if (flightToAirportMap[winner]) {
      setDestinationText(flightToAirportMap[winner]);
    }

    setWinnerText(winner)
  };
  const purchaseTickets = async (e) => {
    try {

      // Retrieve the formData from local storage
      const storedFormData = localStorage.getItem('formData');

      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
        const formFromDay = parsedFormData['departureDate'].split('-')[2]
        const formFromMonth = parsedFormData['departureDate'].split('-')[1]
        const formFromYear = parsedFormData['departureDate'].split('-')[0]
        const formToDay = parsedFormData['returnDate'].split('-')[2]
        const formToMonth = parsedFormData['returnDate'].split('-')[1]
        const formToYear = parsedFormData['returnDate'].split('-')[0]

        setIsLoading(true);
        const response = await fetch(`https://j6s4b9afck.execute-api.us-east-1.amazonaws.com/flights/getticket?budget=${parsedFormData['budget']}&currency=${parsedFormData['currency']}&fromDay=${formFromDay}&fromMonth=${formFromMonth}&fromYear=${formFromYear}&origin=${parsedFormData['startingAirport']}&toDay=${formToDay}&toMonth=${formToMonth}&toYear=${formToYear}&destination=${destinationText}`)

        if (!response.ok) {
          setErrorText("Could not fetch ticket link. Please wait a couple minutes and try again.");
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        
        if (data.link) {
          setTicketLink(data.link)
          // Open the ticket link in a new tab/window
          window.open(data.link, '_blank');
        }

      }
    } catch (error) {
      console.error('Error fetching flight data:', error);
    } finally {
      // Set isLoading back to false when the API call is completed
      setIsLoading(false);
    }

  };

  useEffect(() => {
    if (winnerText !== "") {
      setIsPulsing(true);
    } else {
      setIsPulsing(false);
    }
  }, [winnerText]);

  const onStopSpinning = () => {
    setMustSpin(false);
    const winner = wheelData[prizeNumber].option;
    onFinished(winner);
  };

  return (
    <>
      <Header />
        <div className="wheel-container">
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={wheelData}

            onStopSpinning={onStopSpinning}
          />
          <button className={`spin-button ${winnerText ? "disabled" : ""}`} onClick={handleSpinClick}>
            SPIN
          </button>
        </div>
        <div className="purchase-tickets-button-container">
        <button
          className={`purchase-tickets-button ${!winnerText ? "clear" : ""}${isPulsing ? 'pulse' : ''}`}
          onClick={purchaseTickets}
        >
          {isLoading ? (
            <div className="spinner" />
          ) : (
            <>
              <FontAwesomeIcon icon={faTicket} /> Purchase Tickets{" "}
              <FontAwesomeIcon icon={faTicket} />
            </>
          )}
        </button>
        {ticketLink && <div className="link-text">Ticket Link (in case pop-ups are blocked): <br/><a href={ticketLink} >{ticketLink.substring(0, 30)}...</a></div>}
        {errorText && <div className="error-message">{errorText}</div>}

        </div>
    </>
  );
};

export default SpinningWheel;

